import {useSelector} from "react-redux";
import Modal from "./Modal";
import ModalBody from "./ModalBody/ModalBody";
import ModalHeader from "./ModalHeader/ModalHeader";
import TableContainer from "../TableContainer/TableContainer";

function MobileTournamentModal() {
    const isMobile = useSelector(state => (state.user.isMobile));
    const isMobileLandscape = useSelector(state => (state.user.isMobileLandscape));
    const isTournamentModalOpen = useSelector(state => (state.user.modal.isTournamentModalOpen));
    const roundHistory =  useSelector(state => state.game.finishedRounds);
    const tournamentLeaderBoard = useSelector(state => state.tournament.leaderBoard);
    const tournamentPrevLeaderBoard = useSelector(state => state.tournament.prevLeaderBoard);
    return ((isMobile && isTournamentModalOpen) &&
        <Modal isOpen={isTournamentModalOpen}>
            {(isMobile && !isMobileLandscape) && <ModalHeader/>}
            <ModalBody>
                <TableContainer
                    tabItems={['tabs.current', 'tabs.information', 'tabs.previous']}
                    tableData={[tournamentLeaderBoard, roundHistory, tournamentPrevLeaderBoard]}
                />
            </ModalBody>
        </Modal>
    )
}

export default MobileTournamentModal