import React from 'react';
import {useSelector} from "react-redux";

const Background = ({isMoving}) => {
    const isAnimationActive = useSelector(state => state.game.isAnimationActive);
    const integratorName = useSelector(state => state.user.integratorName)
    return <>
        {
            <img
                src={`/img/${integratorName}/background.png`}
                alt="Background"
                className={`animation__background-image  ${isMoving ? 'animate' : 'stop-animate'} ${!isAnimationActive ? 'opacity-none' : ''}`}
            />
        }
    </>
};

export default Background;
