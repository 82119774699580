import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from "react-redux";
import Multiplier from "../Multiplier/Multiplier";

let interval = 0
const Balloon = () => {
    const {
        multiplier,
        crashedMultiplier,
        sprite,
        id,
    } = useSelector(state => state.game.round);
    const isAnimationActive = useSelector(state => state.game.isAnimationActive);
    const integratorName = useSelector(state => state.user.integratorName)

    const ref = useRef(null)
    const [img, setImg] = useState(1);
    const [odd, setOdd] = useState(0);
    const [currentId, setCurrentId] = useState(null);

    useEffect(() => {
        if (id !== currentId) {
            setImg(1)
            return setCurrentId(id)
        }
    }, [id, currentId, setCurrentId, setImg]);

    useEffect(() => {
        if (sprite) {
            return handleSpriteIndex(sprite)
        }
    }, [sprite]);

    useEffect(() => {
        if (odd !== multiplier) {
            !crashedMultiplier && multiplier && toggleSpriteIndex();
            return setOdd(multiplier)
        }
    }, [multiplier, crashedMultiplier, odd])

    const toggleSpriteIndex = () => {
        interval = interval <= 50 ? interval + 1 : 1;
        let accImgIndex = (interval % 5) === 0 ? 8 : 9
        setImg(accImgIndex)
    }


    const handleSpriteIndex = (index) => {
        setImg(index)
    }
    return <div>
        {
            Array.from({length: 18}, (_, index) => {
                return <img
                    key={index + 1}
                    ref={ref}
                    src={`/img/${integratorName}/balloon/bl-${index + 1}.png`}
                    className={`animation__balloon-img ${img === index + 1 && isAnimationActive ? '' : 'opacity-none'} `}
                    alt="Balloon"
                />

            })
        }
        <Multiplier/>
    </div>
};

export default Balloon;
